/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.dodont {
  width: 100%;
  margin: var(--documentation-component-spacing) 0;
}

.dodont:first-child {
  margin-top: 0;
}

.dodont:last-child {
  margin-bottom: 0;
}

.dodont__card-wrapper {
  /* display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--spacing-unit) * -3);
  margin-bottom: calc(var(--spacing-unit) * -3); */

  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: calc((var(--spacing-unit) * 3));
}

.dodont-card__content {
  border-top: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}

.dodont-card--white {
  border: 1px solid var(--color-gray-900);
  border-bottom-width: 0;
}

/* This makes sure all demos are stretched to the height
   of the highest demo. */
.dodont-card__demo {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.dodont-card__demo .playground__stage {
  border-radius: 0;
}

.dodont-card__content svg {
  position: absolute;
  height: 100%;
  width: 100%;
  /* border-bottom: 2px solid var(--color-red);
  transform-origin: bottom left;
  transform: rotate(-45deg); */
  z-index: 2;
}

.dodont-card__content svg line {
  stroke: var(--color-red);
  stroke-width: 1.5px;
}

.dodont-card__caption {
  border-top: var(--spacing-unit) solid transparent;
}

.dodont-card__caption--do {
  border-top-color: var(--color-green);
}

.dodont-card__caption--dont {
  border-top-color: var(--color-red);
}

.dodont-card__title {
  font-size: var(--font-size-beta);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 1.2em;
  margin-top: calc((var(--spacing-unit) * 1));
}

.dodont-card__caption--do .dodont-card__title {
  color: var(--color-green);
}

.dodont-card__caption--dont .dodont-card__title {
  color: var(--color-red);
}
