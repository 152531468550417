/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: var(--documentation-component-spacing) 0;
}

.image:first-child {
  margin-top: 0;
}

.image:last-child {
  margin-bottom: 0;
}

.image img {
  display: block;
  max-width: 100%;
  height: auto;
}

.image__caption {
  font-size: var(--font-size-gamma);
  line-height: 1.7em;
  color: var(--color-gray-500);
  margin-top: calc(var(--spacing-unit) * 1);
}
