/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.color {
  border: none;
  padding: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  display: inline;
}

.color__demo {
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  transform: translateY(0.06em);
  border-radius: 50%;
  margin-right: 0.1em;
  margin-left: 0.25em;
  position: relative;
  overflow: hidden;
}

.color__demo::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: inherit;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.color__demo::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./CheckerboardPattern.png);
  background-size: 72px;
  background-position: center center;
  z-index: 0;
  border-radius: 50%;
}
