/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.codesandbox {
  margin: var(--documentation-component-spacing) 0;
  width: 100%;
}

.codesandbox:first-child {
  margin-top: 0;
}

.codesandbox:last-child {
  margin-bottom: 0;
}

.codesandbox iframe {
  width: 100%;
  border: none;
  height: calc(var(--spacing-unit) * 50);
  overflow: hidden;
}
