/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.asset-list__asset + .asset-list__asset {
  padding-top: calc(var(--spacing-unit) * 7);
  border-top: 1px dotted var(--color-gray-800);
  margin-top: calc(var(--spacing-unit) * 7);
}

.asset-list__asset-image {
  max-width: calc(var(--spacing-unit) * 75);
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.asset-list__asset-meta {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: calc(var(--spacing-unit) * 4);
  grid-auto-columns: max-content;
}

.asset-list__asset-download::before {
  display: inline-block;
  margin-right: 0.2em;
  margin-left: -1.1em;
  content: '↓ ';
  font-weight: 700;
  text-decoration: none;
  color: var(--color-gray-800);
  transition: color 0.3s;
}

.asset-list__asset-download:hover::before {
  color: var(--color-blue);
}
