/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.figma-embed {
  margin: var(--documentation-component-spacing) 0;
}

.figma-embed:first-child {
  margin-top: 0;
}

.figma-embed:last-child {
  margin-bottom: 0;
}

.figma-embed img {
  display: block;
  max-width: 100%;
}

.figma-embed__loader {
  color: var(--color-gray-500);
  animation-name: pulse;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    color: var(--color-gray-700);
  }
  50% {
    color: var(--color-gray-300);
  }
  100% {
    color: var(--color-gray-700);
  }
}

.figma-embed__error {
  border-bottom: 2px solid var(--color-red);
}

.figma-embed__via {
  font-size: var(--font-size-beta);
  text-align: right;
  width: 100%;
  opacity: 0;
  transition: all 0.3s 0.4s;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.figma-embed:hover .figma-embed__via {
  opacity: 1;
  transition: all 0.4s 0s;
}

.figma-embed__via:focus {
  opacity: 1;
  transition: all 0s;
}
