/**
 * Copyright 2021 Marius Wilms, Christoph Labacher. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.react-playground {
  width: 100%;
  margin: var(--documentation-component-spacing) 0;
  overflow: hidden;
}

.react-playground:first-child {
  margin-top: 0;
}

.react-playground:last-child {
  margin-bottom: 0;
}

.react-playground__content {
  position: relative;
}

.react-playground__stage-frame {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  flex-grow: 1;
}

.react-playground--hide .react-playground__stage-frame {
  opacity: 0;
}

.react-playground__loading-message {
  text-align: center;
}

.recat-playground__caption {
  font-size: var(--font-size-gamma);
  line-height: 1.7em;
  color: var(--color-gray-500);
  margin-top: calc(var(--spacing-unit) * 1);
}

.react-playground .playground {
  margin: 0;
}

.react-playgroundr .playgound__stage {
  border-radius: 3px;
  overflow: hidden;
}

.react-playground--show-source .playground__stage {
  border-radius: 3px 3px 0 0;
}

.react-playground--full-size .playground__stage {
  border-radius: 0;
}

.react-playground__show-source {
  position: absolute;
  bottom: 0px;
  height: 35px;
  padding: 10px;
  line-height: 15px;
  background-color: var(--color-gray-1000);
  font-family: var(--font-family-mono);
  color: var(--color-gray-100);
  font-size: var(--font-size-beta);
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s 0.4s;
  border-radius: 3px;
}

.react-playground--full-size .playground__show-source {
  border-radius: 0;
}

.react-playground__content:hover .react-playground__show-source,
.react-playground--show-source .react-playground__show-source,
.react-playground__show-source:focus {
  opacity: 1;
  transition: all 0.4s 0s;
}

.react-playground .code-block {
  margin: 0;
}

.react-playground .code-block__code {
  background-color: #f5f9fa;
  border-top: 2px solid var(--color-gray-950);
  border-radius: 0 0 3px 3px;
}

.react-playground .code-block__copy {
  top: 10px;
  right: 12px;
  z-index: 2;
}

.react-playground--dark .react-playground__loading-message {
  color: #fff;
}
.react-playground--dark .react-playground__show-source {
  color: var(--color-gray-900);
}
