/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.breadcrumbs {
  list-style: none;
}

.breadcrumbs__crumb {
  display: inline;
}

.breadcrumbs__crumb:after {
  color: var(--color-gray-600);
  content: ' / ';
}

.breadcrumbs__crumb a {
  color: var(--color-gray-600);
  text-decoration: none;
  transition: color 0.2s;
}
.breadcrumbs__crumb a:hover {
  color: var(--color-blue);
}
