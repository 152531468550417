/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

:root {
  --color-white: #ffffff;
  --color-black: hsl(0, 0%, 0%);
  --color-blue: #0e26fc; /* Electric Blue */
  --color-green: #52d0af; /* Toothpaste Green */
  --color-teal: #b7edf2;
  --color-teal--dark: #00838f;
  --color-yellow--light: rgb(255, 242, 180);
  --color-yellow: #ffe874;
  --color-yellow--dark: #866e00;
  --color-orange: #fed28c;
  --color-red: #ee645d;

  --color-gray-200: #333b40; /* Gray-200 25% */
  --color-gray-300: #4f5559; /* Gray-300 35% */
  --color-gray-500: #697980; /* Gray-500 50% */
  --color-gray-600: #829199; /* Gray-600 60% */
  --color-gray-700: #adb0b3; /* Gray-600 60% */
  --color-gray-800: #d2d6d9; /* Gray-800 85% */
  --color-gray-900: #e1e4e6; /* Gray-900 90% */
  --color-gray-950: #e8eaed; /* Gray-950 93% */
  --color-gray-1000: #f2f6f7; /* Gray-1000 97% */

  /* Font Families */
  --font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-mono: 'SF Mono', Menlo, monospace;

  --font-size-alpha: 10px;
  --font-size-beta: 12px;
  --font-size-gamma: 15px;
  --font-size-delta: 24px;
  --font-size-epsilon: 34px;
  --font-size-zeta: 48px;

  /* Spacing */
  --spacing-unit: 8px;
  --documentation-component-spacing: calc((var(--spacing-unit) * 5));
}
